// AuthContext.js

import React, { createContext, useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext({
  token: null,
  role: null,
  res: null,
  success: null,
  userId: null,
  setUser: () => { },
  isLoggedIn: null,
  setIsLoggedIn: () => { },
  refreshPage: null,
  setRefreshPage: () => { },
  handleLogout: () => { },
  errorHandleLogout: () => { },
});

export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  const [res, setRes] = useState(null);
  const [success, setSuccess] = useState(null)
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const [refreshPage, setRefreshPage] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false);



  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  //  console.log(dbHost);
  //  const backendURL = process.env.BACKEND_UR;
  // console.log(backendURL); 
  // console.log(process.env);
  const errorHandleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("skillList");
    localStorage.removeItem("userId");
    localStorage.removeItem("merchantTransactionId");
    localStorage.removeItem("popupShown")
    setRole(null);
    setRes(null);
    setSuccess(null);
    setUserId(null);
    navigate('/')
    toast.success("Logout Successfully !", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      setIsLoggedIn(false);
      localStorage.removeItem("popupShown")
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("skillList");
      localStorage.removeItem("userId");
      localStorage.removeItem("merchantTransactionId");
      setRole(null);
      setRes(null);
      setSuccess(null);
      setUserId(null);
      toast.success("Logout Successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      // Show a cancellation notification using toast
      const roles = localStorage.getItem("role")
      toast.info('Logout cancelled. You are still logged in.', {
        onClose: () => {
          if (roles === "user") navigate("/userPage");
          else navigate("/admin");
        },
        position: toast.POSITION.BOTTOM_RIGHT,
      });

    }
  }

  const setUser = (user) => {
    const headers = {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
    };
    // const apiURL="http://localhost:8080/users/login"
    // const apiBackendURL="http://backendvideo.stepsoflearningprocess.com:8001/users/login"
    // console.log("token",token)
    // console.log("success",success);
    try {
      axios.post(`${BACKEND_URL}/users/login`, user, { headers: headers })
        .then((res) => {
          if (res.data.success === true) {
            const tokenFromStorage = localStorage.setItem('token', res.data.data.token);
            // console.log(res.data.data.token);
            if (tokenFromStorage) {
              setToken(localStorage.getItem('token'));

            }
            localStorage.setItem("userId", res.data.userId)
            setIsLoggedIn(true);
            setRole(res.data.role);
            localStorage.setItem("role", res.data.role)
            setRes(res);
            setSuccess(res.data.success);
            setUserId(res.data.userId);

            if (res.data.success === true && res.data.role === "admin") {
              alert("Successfull Admin Login")
              navigate("/admin");
            } else if (res.data.success === true) {
              alert("Successfull User Login")
              navigate("/userPage");
            }
          }
          else {
            alert("Wrong Credentials")
          }
        });
    } catch (err) {
      alert("Session Timeout", err);
      localStorage.removeItem("token");
      handleLogout();
      setIsLoggedIn(false);
      navigate("/")
    }

  };

  return (
    <AuthContext.Provider value={{ token, role, res, success, userId, setUser, isLoggedIn, setRefreshPage, setIsLoggedIn, refreshPage, handleLogout, errorHandleLogout }}>
      {children}
      <ToastContainer autoClose={1000} />
    </AuthContext.Provider>
  );
};

export default AuthContext;

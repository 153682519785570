import image1 from "../images/node.png";
import tagg from "../images/2999.png";
import pin2 from '../images/pin2.png'
export const NodeForm={
    img: image1,
    tagg: tagg,
    amount: 2999,
    courseName:"Node JS",
    quote:"Exclusive! Limited Slots Available. Join Now. Starting Date ➡ 17 February, 2024"

}

export const NodeAbout={
    title: "Node JS",
    one: {
        title: "Node JS",
        content: "Node.js is a JavaScript runtime built on Chrome's V8 JavaScript engine, extending the capabilities of JavaScript to enable server-side programming and facilitating the development of scalable and efficient web applications."
    },
    two:{
        courseDetails: {
            Instructor: 'Priyabrata Dash',
            Duration: '16 hours Live Session (Weekend only)',
            AverageDuration: '1 month',
            Level: 'Beginner To High',
            FreeOrPaid: 'Paid',
            Fees: '2999/-',
            Certificate: 'Yes',
          },
    },
    three:{
        courseFacilities:{
            one: 'Projects to add to your portfolio',
            three: 'Lesson on solving interview questions with Node JS',
        }
    },
    four:{
        courseContent:{
            one: ' Introduction to Node.js',
            two: ' Node.js Modules and NPM',
            three: 'Asynchronous Programming with Callbacks and Promises',
            four: 'Express.js - Building Web Applications',
            five: 'Database Integration with MongoDB and Mongoose',
            six: 'Testing in ReactRESTful API Development'
        }
    }
}

export const NodeCourse={
    title: "Node JS Course",
    cousrseDetails:{
        duration: "16.5 hours on-demand video",
        articles: 3,
        downloadResources: 1
    },
    requirements:{
        one: 'No coding experience is required! We will start from very basics and take you to the advanced level!',
        two: 'You just need a computer, web browser and code editor',
        three: 'Propper knowledge of Javascript will be a plus!'
    }
}

export const NodeAboutInstructor={
    img: pin2,
    name: "Priyabrata Dash",
    designation: "Full-stack developer",
    about: "Greetings, My name is Priyabrata Dash, and I have 8 years of substantial experience in the field of information technology. As a seasoned full-stack developer, I have navigated the complex terrain of technology by applying my knowledge of JavaScript, React, and Node.js to a variety of projects. My journey has been marked by real-time, hands-on experience, a testament to my commitment to staying at the forefront of technological advancements. I currently work for a reputable MNC and have the honour of developing my skills in a challenging and dynamic setting.",
    exp: 9,
}



import React from 'react'
import playVideo from "../assets/playVideo.png";

export const PlaylistComponent = ({ filteredMedias, selectedVideo, VIDEO_URL, handleVideoClick, selectedTutor }) => {
    return (
        <>
            {filteredMedias && filteredMedias.length > 0 ? (
                filteredMedias.map((media, index) => (
                    <div
                        key={media.id}
                        className={`playlist-item ${selectedVideo === `${VIDEO_URL}/${media.video}` ? "active" : ""}`}
                    >
                        <div
                            className="v-list-item"
                            onClick={() => handleVideoClick(`${VIDEO_URL}/${media.video}`)}
                        >
                            <div className="video-title">
                                {index + 1})&nbsp;
                                {media.title}
                            </div>
                            <div className="video-duration">
                                <img
                                    width="15"
                                    height="15"
                                    src={playVideo}
                                    alt="laptop-play-video--v1"
                                />
                                &nbsp;
                                <span>{media.videoDuration}</span>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className={`playlist-item active`}>
                    <div className="v-list-item">
                        <div className="video-title">
                            &nbsp; No videos found of this tutor ➡ {selectedTutor}.
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
import React, { Fragment, useEffect, useState } from "react";
import "./css/uploadListCSS.css";
import AuthContext from "../context/AuthContext";
import { Navigate, useNavigate } from "react-router";
import playVideo from "../assets/playVideo.png";
import pipelineMenu from "../assets/pipelineMenu.png";
import { Tour } from "./Tour";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { BottomHeader } from "./bottomHeader/BottomHeader";
import downArrow from "../assets/down direction.png";
import { PlaylistComponent } from "./PlaylistComponent";

export const VideoList = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const VIDEO_URL = process.env.REACT_APP_VIDEO_URL;
    const navigate = useNavigate();
    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            window.location.reload();
        };
        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, [, navigate]);
    const { role, success, userId, setIsLoggedIn, handleLogout, errorHandleLogout } =
        React.useContext(AuthContext);

    // console.log("user id", userId)
    // console.log("user id:", userId)
    const token = localStorage.getItem("token");

    const [scrollerHeight, setScrollerHeight] = useState(0);
    if (token && role !== "user" && success === true) {
        <Navigate to="/login" />;
    }
    const [toggle, setToggle] = useState(false);

    const [medias, setMedias] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const [showCourse, setShowCourse] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [toggleForm, setToggleForm] = useState(true);
    const [selectedTutor, setSelectedTutor] = useState("");
    const [apiResponse, setApiResponse] = useState({
        message: "",
        user: {
            isPYTHON: null,
            isJAVA: null,
            isTESTING: null,
            isSOFTSKILL: null,
            isHTML: null,
            isCSS: null,
            isJS: null,
            isREACT: null,
            isNODE: null,
            isDELETED: null,
        },
        success: null,
    });
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
        // Assume you make an API call here and set the response
        // For example, using the fetch function
        // Replace this with your actual API call
        try {
            const userID = localStorage.getItem("userId");
            // console.log("USERID", userID)
            fetch(`${BACKEND_URL}/users/profile/${userID}`, {
                headers,
            })
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data.user.bio._id)

                    setApiResponse(data);
                    // console.log("user", data.user);
                    if (data.user.bio) {
                        setToggleForm(false);
                    } else {
                        setToggleForm(true);
                    }
                });
        } catch (err) {
            alert("User Details Not Found", err);
            errorHandleLogout();
        }
    }, [userId]);
    // Empty dependency array ensures the effect runs once after the initial render

    const users = apiResponse.user;

    const handleToggle = () => {
        setToggle(!toggle);
        setShowCourse(false);
    };
    const handleShowCourse = () => {
        setShowCourse(!showCourse);
        setShowProfile(false);
    };
    const handleShowProfile = () => {
        setShowProfile(!showProfile);
        setShowCourse(false);
    };
    useEffect(() => {
        const trueValuesArray = Object.keys(apiResponse.user)
            .filter((key) => apiResponse.user[key] === true)
            .map((key) => key.substring(2).toLowerCase()); // Extract substring and convert to lowercase

        // console.log("trueValuesArray", trueValuesArray[0]);
        if (trueValuesArray.length > 0) {
            localStorage.setItem("skillList", trueValuesArray);
            setSelectedLanguage(trueValuesArray[0]);
        }
    }, [apiResponse]);

    useEffect(() => {
        const calculateScrollerHeight = () => {
            const vPaneHeight = document.querySelector(".v-pane").clientHeight;
            const courseContentHeight = document.querySelector("h3").clientHeight;
            setScrollerHeight(vPaneHeight - courseContentHeight);
            const scroller = document.querySelector(".scroller");
            // set height of scroller
            if (scroller) {
                scroller.style.height = `${vPaneHeight - courseContentHeight}px`;
            }
        };
        calculateScrollerHeight();

        // Re-run if window resize affects layout
        window.addEventListener("resize", calculateScrollerHeight);

        return () => {
            window.removeEventListener("resize", calculateScrollerHeight);
        };
    }, []);

    const [selectedVideo, setSelectedVideo] = useState(null);

    const handleVideoClick = (videoUrl) => {
        // console.log(videoUrl)
        setSelectedVideo(videoUrl);
    };

    const handleChange = (event) => {
        setSelectedLanguage(event.toLowerCase());
        setToggle(!toggle);
    };
    useEffect(() => {
        // Set the skillList in local storage

        // Use setTimeout to print the skillList after 1 second
        const timeoutId = setTimeout(() => {
            const skill = localStorage.getItem("skillList");

            const stringArray = skill.split(",");

            // Get the first string
            const firstString = stringArray[0];
            console.log("firstString", firstString);
        }, 1000);

        // Cleanup the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        // console.log("selectedLanguage", selectedLanguage);

        fetch(`${BACKEND_URL}/courses/get_course/${selectedLanguage}`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data) setMedias(data.courseData);
                else {
                    alert("Session Timeout");
                    errorHandleLogout();
                }
            })
            .catch((err) => {
                alert("Session Timeout", err);
                errorHandleLogout();
            });
    }, [selectedLanguage]);
    const [sortedMedias, setSortedMedias] = useState([]);
    const [uniqueTutorNames, setUniqueTutorNames] = useState([]);

    useEffect(() => {
        if (medias && medias.length > 0) {
            // Sort the medias by createdAt
            const sortedMedias = [...medias].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setSortedMedias(sortedMedias);

            // Extract unique tutor names
            const uniqueNames = Array.from(new Set(sortedMedias.map(obj => obj.tutor)));
            setUniqueTutorNames(uniqueNames);
        }
    }, [medias]);

    console.log(uniqueTutorNames);
    const handleTutorChange = (event) => {
        setSelectedTutor(event.target.value);
    };

    const filteredMedias = selectedTutor
        ? sortedMedias.filter((media) => media.tutor === selectedTutor)
        : sortedMedias;

    const driverObj = driver({
        showProgress: true, // Because everyone loves progress bars!
        steps: [
            {
                element: "#step1",
                popover: {
                    title: "Video Player",
                    description: "Here video will be played ",
                },
            },
            {
                element: "#step2",
                popover: {
                    title: "Title",
                    description: "Selected Course Title",
                },
            },
            {
                element: "#step3",
                popover: {
                    title: "Content",
                    description: "List of lectures will be shown here",
                },
            },
            {
                element: "#step4",
                popover: {
                    title: "Menu",
                    description: "Here you can access your courses & update your profile details",
                },
            },
            // More magical steps...
        ],
    });
    const [showPopup, setShowPopup] = useState(false);
    const [startTour, setStartTour] = useState(false);
    useEffect(() => {
        const popupNotShown = !localStorage.getItem("popupShown");
        if (token && popupNotShown) {
            setShowPopup(true);
            localStorage.setItem("popupShown", "true");
        }
    }, [token]);
    useEffect(() => {
        if (startTour) {
            driverObj.drive();
        }
    }, [startTour]);

    const [activeDropdown, setActiveDropdown] = useState(null);

    useEffect(() => {
        if (uniqueTutorNames && uniqueTutorNames.length > 0) {
            setActiveDropdown(uniqueTutorNames[0]);
            setSelectedTutor(uniqueTutorNames[0]);
        }
    }, [uniqueTutorNames]);

    const toggleDropdown = (tutorName) => {
        //  console.log("tutorName",tutorName)
        setSelectedTutor(tutorName);
        setActiveDropdown(tutorName === activeDropdown ? null : tutorName);
    };
    return (
        <Fragment>
            <section className="videoList">
                <div className="v-container">
                    <div className="v-pane">
                        <div className="embed-responsive embed-responsive-16by9" id="step1">
                            {selectedVideo ? (
                                <div className="video-player">
                                    <video
                                        id="vp"
                                        preload="auto"
                                        width={640}
                                        height={360}
                                        controls
                                        src={selectedVideo}
                                        controlsList="nodownload"
                                    ></video>
                                </div>
                            ) : (
                                <div className="video-player">
                                    <video
                                        preload="auto"
                                        width={640}
                                        height={360}
                                        controlsList="nodownload"
                                        controls
                                    ></video>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="v-list">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <h3 id="step2">
                                {selectedLanguage
                                    ? ` ${selectedLanguage.toUpperCase()} Content`
                                    : "HTML Content"}
                            </h3>

                            <img
                                onClick={handleToggle}
                                style={{ cursor: "pointer" }}
                                width="30"
                                height="30"
                                src={pipelineMenu}
                                alt="menu"
                                id="step4"
                            />
                        </div>

                        {toggle ? (
                            <div className="sidebar">
                                <p onClick={handleShowCourse}>Select Your Course</p>
                                <p onClick={handleShowProfile}>My Profile </p>

                                {showCourse && (
                                    <div className="sub_sidebar">
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            {/* <label htmlFor="languages">Select a language:</label>
                                        <select id="languages" onChange={handleChange} value={selectedLanguage}>
                                            <option value={null}>Select</option>
                                            
                                        </select> */}

                                            {users
                                                ? Object.keys(users).map((language) =>
                                                    language.startsWith("is") &&
                                                        users[language] ? (
                                                        <button
                                                            style={{ marginTop: "1rem" }}
                                                            key={language}
                                                            value={language}
                                                            onClick={() =>
                                                                handleChange(
                                                                    language.substring(2)
                                                                )
                                                            }
                                                        >
                                                            {language.substring(2)}{" "}
                                                            {/* Remove the 'is' prefix */}
                                                        </button>
                                                    ) : null
                                                )
                                                : errorHandleLogout()}
                                        </div>
                                    </div>
                                )}

                                {showProfile && (
                                    <div className="sub_sidebar">
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <button
                                                style={{ marginTop: "1rem" }}
                                                onClick={() => {
                                                    navigate("/change_password");
                                                    setToggle(!toggle);
                                                }}
                                            >
                                                Update Credential
                                            </button>

                                            {toggleForm ? (
                                                <button
                                                    style={{ marginTop: "1rem" }}
                                                    onClick={() => navigate("/add_bio_data")}
                                                >
                                                    Add Bio Details
                                                </button>
                                            ) : (
                                                <button
                                                    style={{ marginTop: "1rem" }}
                                                    onClick={() => navigate("/update_bio_data")}
                                                >
                                                    Update Bio Details
                                                </button>
                                            )}
                                            <button
                                                id="deleteUser"
                                                onClick={() => navigate("/user/delete")}
                                            >
                                                Delete User
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                        <div
                            className="scroller"
                            data-wow-delay="00ms"
                            data-wow-duration="1500ms"
                            style={{ visibility: "visible", height: "32.5rem" }}
                            id="step3"
                        >
                            {uniqueTutorNames && uniqueTutorNames.length > 0 && uniqueTutorNames.map((tutorName, index) => (
                                <>


                                    <div

                                        className="sirSelector"
                                        onClick={() => toggleDropdown(tutorName)}
                                        key={tutorName + index}
                                    >
                                        {tutorName}{" "}
                                        <img
                                            width="15"
                                            height="15"
                                            src={downArrow}
                                            alt="expand-arrow--v1"
                                        />
                                    </div>

                                    {activeDropdown === tutorName &&
                                        <PlaylistComponent
                                            key={index}
                                            filteredMedias={filteredMedias}
                                            selectedVideo={selectedVideo}
                                            VIDEO_URL={VIDEO_URL}
                                            handleVideoClick={handleVideoClick}
                                            selectedTutor={selectedTutor}
                                        />
                                    }
                                </>
                            ))}

                        </div>

                    </div>
                </div>




                {/* <hr />
        <BottomHeader />
        <hr /> */}
                {showPopup ? <Tour setShowPopup={setShowPopup} setStartTour={setStartTour} /> : ""}
            </section>
            {/* <BottomHeader /> */}
        </Fragment>
    );
};

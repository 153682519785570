import image1 from "../images/hr_banner.png";
import tagg from "../images/tagg.png";
import pin2 from '../images/mukesh.png'
import radhe from "../images/radhe.png"
export const HRForm={
    img: image1,
    tagg: tagg,
    amount: 1999,
    courseName:"HR",
    quote:"Exclusive! Limited Slots Available. Join Now."

}

export const HRAbout={
    title: "Corporate HR Training/NLP",
    one: {
        title: "HR Data Analytics",
        content: "JavaScript, often abbreviated as HR, is a programming language that is one of the core technologies of the World Wide Web, alongside HTML and CSS."
    },
    two:{
        courseDetails: {
            Instructor: 'Mukesh Kumar Mohanty',
            Duration: '56 hours Live Session (Weekend only)',
            AverageDuration: '45 month',
            Level: 'Beginner To High-Corporate Professional',
            FreeOrPaid: 'Paid',
            AverageFees: '1999/-',
            Certificate: 'Yes',
          },
    },
    three:{
        courseFacilities:{
            one: 'On job practical HR approach',
            two: 'Letter of recommendation, ',
            three: 'Lesson on solving interview questions in corporate,PSU',
        }
    },
    four:{
        courseContent:{
            one: 'HR Management',
            two: 'Payroll management',
            three: 'HR analytics',
            four: 'Labour laws',
            five: 'People Analytics',
            six: 'SAP'
        }
    }
}

export const HRCourse={
    title: "Corporate HR Training/NLP",
    cousrseDetails:{
        duration: "56 hours on-demand LMS",
        articles: 3,
        downloadResources: 1
    },
    requirements:{
        one: 'Have a willingness to be a HR-We  will take you to the next level!',
        two: 'You just need to have a basic inclination For Peoples management, eager to learn and explore new verticals of HR',
        three: "Basic knowledge of HR, People's management",
    }
}

export const HRAboutInstructor={
    img: pin2,
    name: "Mukesh Mohanty",
    designation: "Principal Trainer",
    about: 'Greetings , I am Mukesh Mohanty, and I have a decade  proven  substantial  10 plus years of experience in the field of HR manangement,Corporate HR,HR analytics. Data HR. Data Analytics, Change management  etc. As a seasoned Experienced HR researcher, my aim is to develop the real human into resource,bringing back human to Corporate bisiness market.Ethical HR  should be aim to create an ambience to manifest a"profit with purpose" business model.',
    exp: 12,
}

export const HRTeamOne={
    img: pin2,
    name: "Mukesh Mohanty",
    designation: "Principal Mentor",
    list:{
        a: 'Journalist turned lecturer in Communication management at Xaviers to HR Research professional and a pro-bonno corporate /skill development trainer. ',
        b: 'Mukesh, a Master degree holder from University of Stirling in International Studies, also an alumuni of Utkal university,JNU',
        c: "Has an extensive 12 + years experience in core, corporate HR operations/functions in multiple corporate and government sectors of importance. ",
        d: "Mukesh,a serial thought leader with numerous 'firsts' to his credits, he spent more than 10 years working across various organizations of repute in multi-disciplinary fields of Engineering, Automobile mfg., Tyre mfg., Synthetic Yarn mfg. and Petrochemicals in India and around 16 months years in West Germany with M/S Daimler Benz AG and others. ",
        e:"Marked his presence in many international and national podiums of importance including AIHRD. ",
        f:"Trained about 300 + graduates from across the country and abroad till date in HR, skill development, Strategic planning, Leadership management,Cross-Cultural programmes, etc. ",
        g:"Currently leading the Daya Group of companies as VP-Operations and Principal Trainer heading the L&D, R&D. ",
        h:"With a mission of offering superior professional services in the Executive search, HR developmental-research based human potential upliftment he is playing a significant role in successfully being the  key player in the corporate segments till date. ",
        i:"As an academician, he had been associated with a renowned management college including Xavier Group teaching/mentoring  students for  years and being very popular with a heart-through with some of his students till date. companionship ",
        j:"Having founded associated with Daya Group,in 2021, he has worked tirelessly to provide value, high-ended quality services to top notch corporates including large corporations and MSMEs pan-India. ",
        k:"Daya consultancy services and its team- a leading/trending brand name in its space and continues to set up new benchmarks in Learning,Research and development by continuous improvisation under the vigilant guidance of its CEO-MR. Daya shankar & VP-Mr.Mukesh Mohanty. ",
        l:"DCS being a budding age start-up is offering superior professional services in the IT,HR,CSR,Skill development, and Business Consultanting segments has been successfully proven to be a key player in the corporate segments till date.",
    },
    slogan:"“HR is  the “Voice of the voiceless”.Lets bring Back “HUMAN” to Resources”   ",
    exp: 12,
}
export const HRTeamTwo={
    img: radhe,
    name: "Rajlaxmi Patra ",
    designation: "Asst. Manager- L & D",
    list:{
        a: 'An accomplished Assistant Training Manager at Daya Consultancy Services, Rajlaxmi bags a Master degree in Business Administration along with 2 plus years of experience in varieties of HR functions especially in IT domains - core business platform.  ',
        
       
    },
    exp: 2,
    highlights:{
        a:"She has successfully gained experience in Management Corroboration in HR.",
        b:"She is representing Daya group - As Manager - HR(Operations). ",
    }
}
export const HRTeamThree={
    img: "hgh",
    name: "Rituparna Priyadarshini ",
    designation: "Asst. Manager- L & D",
    list:{
        a: 'An diversified Associate Official for Daya’s Training and Development team.She has Succesfully completed Her Master in Business Administration.  ',
        
       
    },
    highlights:{
        a:"The passionate new age food blogger-Ritu tried her hands to dip into elixir of HR, Training and Development tentacles .",
        b:"She is representing Daya group - As Manager - HR(Operations). She is been accredited as A soft skills, Organizational change management ,IR(Industrial Relations) trainer for DCS. ",
    }
}


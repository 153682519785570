import React from 'react'
import './component/style.css'
import { CourcesAbout, CourcesAbout_Instructor, CourcesForm, CourcesJavascript, DayaAbout } from './component'
import { HTMLCSSAbout, HTMLCSSAboutInstructor, HTMLCSSCourse, HTMLForm } from './data/HTMLContent'
import { usePageMetadata } from '../hooks/usePageMetadata '

export const HTML_CSS_Cource = () => {
    const title = "HTML/CSS Certification";
    const description = "Your page description goes here.";
    const keywords = "keyword1, keyword2, keyword3";

    usePageMetadata(title, description, keywords);
    return (
        <>
            <CourcesForm data={HTMLForm} />
            <CourcesJavascript data={HTMLCSSCourse}/>
            <CourcesAbout data={HTMLCSSAbout} />
            <CourcesAbout_Instructor data={HTMLCSSAboutInstructor} />
            <DayaAbout data={HTMLForm} />
        </>
    )
}

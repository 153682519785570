import React, { useContext, useEffect, useState } from 'react'
import "../../login/forgo.css"
import axios from 'axios';
import { useNavigate } from 'react-router';
import AuthContext from '../../context/AuthContext';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link} from 'react-router-dom';

export const ChangePassword = () => {

    const { handleLogout, setIsLoggedIn } = useContext(AuthContext);

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const SECRET_KEY_CAPTCHA = process.env.REACT_APP_SECRET_KEY_CAPTCHA;

    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const userID = localStorage.getItem("userId")
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}/users/profile/${userID}`, {
                    headers,
                });
                const data = await response.json();
                setEmail(data.user.email);
                setName(data.user.name);
            } catch (err) {
                alert("Session Timeout", err);
                localStorage.removeItem("token");
                handleLogout();
                setIsLoggedIn(false);
                navigate("/");
            }
        };

        fetchData(); // Invoke the async function here
    }, [userID, headers]); // Add dependencies to the dependency array


    const postData = {
        name: name,
        email: email,
        password: password
    }
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

    const handleCaptchaChange = (value) => {
        // This callback will be triggered when the user completes the reCAPTCHA challenge
        setIsCaptchaVerified(true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(postData);
        if (isCaptchaVerified) {
            // Proceed with form submission
            axios.post(`${BACKEND_URL}/users/update_user/${userID}/${userID}`, postData, { headers: headers })
                .then((res) => {
                    if (res.status === 200) {
                        // Assuming the server returns the OTP in the response
                        console.log(res.data.success);
                        if (res.data.success === true) {
                            toast.success("Data is updated successfully!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            navigate("/userPage");

                        }
                    } else {
                        toast.error("Unable to update!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((err) => {
                    alert("Session Timeout", err);
                    handleLogout();
                    setIsLoggedIn(false);
                    navigate("/");
                });
        } else {
            // Display an error or prevent submission
            toast.error("Please complete the reCAPTCHA challenge!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

    }

    return (
        <>
            <div className="forgotPass">
                <form className="genOTP" onSubmit={handleSubmit}>
                    <h4>Update User Details</h4>
                    <div className="project_mail">
                        <div className="boi_data">
                            <label htmlFor="name">
                                Name  </label>
                            <input
                                type="name"
                                style={{ marginRight: "1rem" }}
                                id="id"
                                placeholder="Enter your name"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="boi_data">
                            <label htmlFor="Email">
                                Email Id
                            </label>

                            <input
                                style={{ marginRight: "1rem" }}
                                type="email"
                                id="id"
                                placeholder="Enter your Email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div></div>


                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'baseline' }}>
                            <label htmlFor="velOTP">Password:
                            </label>

                            <input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                name="pass"
                                placeholder="Password"
                                value={password} onChange={(e) => setPassword(e.target.value)}
                            />
                            <i
                                className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                aria-hidden="true"
                                onClick={togglePasswordVisibility}
                                style={{ cursor: 'pointer' }}
                            ></i>
                        </div>
                        <br />
                        <ReCAPTCHA
                            sitekey={SECRET_KEY_CAPTCHA}
                            onChange={handleCaptchaChange}
                        />
                        <div style={{ display: "flex", flexDirection: "row", columnGap: "4rem" }}>
                            <button type="submit" className="OTP1" style={{ alignItems: 'center' }}>
                                Update
                            </button>
                            <Link to="/userPage">
                            <button type="button" className="back_button">
                                Go Back
                            </button>
                            </Link>

                        </div>
                    </div>
                </form>
                <ToastContainer autoClose={2500}/>
            </div>
        </>

    )
}





import React, { useEffect, useRef } from 'react';
import 'react-html5video/dist/styles.css'
import './mediaScreen.css';
import { useLocation } from 'react-router-dom';
// import logo from '../images/logo.png'

export const ReactVideoPlayer = ({ path }) => {
  // 
  const location = useLocation();
  const videoRef = useRef(null);


  useEffect(() => {
    const handleCanPlay = () => {
      videoRef.current.play();
    };

    const handleUserInteraction = () => {
      // If the user interacts with the video (e.g., clicks on it),
      // we want to play it again.
      videoRef.current.play();
    };

    const videoElement = videoRef.current;

    if (videoElement && videoElement.addEventListener) {
      videoElement.addEventListener('canplay', handleCanPlay);
      videoElement.addEventListener('click', handleUserInteraction);
    }

    return () => {
      if (videoElement && videoElement.removeEventListener) {
        videoElement.removeEventListener('canplay', handleCanPlay);
        videoElement.removeEventListener('click', handleUserInteraction);
      }
    };
  }, []);
  return (
    <>
      <section>
        <div className='container-fluid-player'>
          <video
            ref={videoRef}
            autoPlay
            muted
            loop
            playsInline
            controls={false}
            controlsList="nodownload"
            className={`${location.pathname === '/' ? "auto_video_playback " : "auto_video_playback_course"}`}
          >
            <source src={`${path}`} type="video/mp4" />
          </video>
        </div>
      </section>
    </>
  )
}



import React from 'react'
import { usePageMetadata } from '../hooks/usePageMetadata ';
import { CourcesAbout, CourcesAbout_Instructor, CourcesForm, CourcesJavascript, DayaAbout } from './component';
import { NodeAbout, NodeAboutInstructor, NodeCourse, NodeForm } from './data/NodeContent';

export const Node_Course = () => {
    const title = "Node Certification";
    const description = "Your page description goes here.";
    const keywords = "keyword1, keyword2, keyword3";
    usePageMetadata(title, description, keywords);

    return (
        <>
        <CourcesForm data={NodeForm} />
        <CourcesJavascript data={NodeCourse}/>
        <CourcesAbout data={NodeAbout} />
        <CourcesAbout_Instructor data={NodeAboutInstructor} />
        <DayaAbout data={NodeForm} />
      </>
    )
}


import image1 from "../images/banner2.2.png";
import tagg from "../images/tagg.png";
import pin2 from '../images/pin2.png'
export const JSForm={
    img: image1,
    tagg: tagg,
    amount: 149,
    courseName:"JavaScript",
    quote:"Exclusive! Limited Slots Available. Join Now.  Starting Date ➡ 17 February, 2024"
}

export const JSAbout={
    title: "Javascript",
    one: {
        title: "JAVA SCRIPT",
        content: "JavaScript, often abbreviated as JS, is a programming language that is one of the core technologies of the World Wide Web, alongside HTML and CSS."
    },
    two:{
        courseDetails: {
            Instructor: 'Priyabrata Dash',
            Duration: '16 hours Live Session (Weekend only)',
            AverageDuration: '1 month',
            Level: 'Beginner To High',
            FreeOrPaid: 'Paid',
            Fees: '149/-',
            Certificate: 'Yes',
          },
    },
    three:{
        courseFacilities:{
            one: 'Projects to add to your portfolio',
            two: 'Bonus section on Git & GitHub , basic information on HTML & CSS',
            three: 'Lesson on solving interview questions with JavaScript',
        }
    },
    four:{
        courseContent:{
            one: 'Javascript Fundamentals',
            two: 'Data types',
            three: 'Objects, Arrays',
            four: 'Functions,events',
            five: 'Promises,async/await',
            six: 'Dom'
        }
    }
}

export const JSCourse={
    title: "JavaScript Course",
    cousrseDetails:{
        duration: "16.5 hours on-demand video",
        articles: 3,
        downloadResources: 1
    },
    requirements:{
        one: 'No coding experience is required! We will start from very basics and take you to the advanced level!',
        two: 'You just need a computer, web browser and code editor',
        three: 'Basic knowledge of HTML and CSS will be a plus, but it is not mandatory!'
    }
}

export const JSAboutInstructor={
    img: pin2,
    name: "Priyabrata Dash",
    designation: "Full-stack developer",
    about: "Greetings, My name is Priyabrata Dash, and I have 8 years of substantial experience in the field of information technology. As a seasoned full-stack developer, I have navigated the complex terrain of technology by applying my knowledge of JavaScript, React, and Node.js to a variety of projects. My journey has been marked by real-time, hands-on experience, a testament to my commitment to staying at the forefront of technological advancements. I currently work for a reputable MNC and have the honour of developing my skills in a challenging and dynamic setting.",
    exp: 9,
}


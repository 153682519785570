import React from 'react'
import { Link } from 'react-router-dom'
import { usePageMetadata } from '../hooks/usePageMetadata ';

export const ThankYou = () => {
  const title = "Thank You";
  const description = "Your page description goes here.";
  const keywords = "keyword1, keyword2, keyword3";

  usePageMetadata(title, description, keywords);
  return (
    <>
      <div className="contentThankYou">
        <div className="wrapper-1">
          <div className="wrapper-2">
            <h1>Thank you !</h1>
            <p>Thanks for joining Steps Of Learning Process program ...</p>
            <p></p>
            <br /><br />
            <Link to="/" className="go-home">go home</Link>
          </div>

        </div>
      </div>
      <link
        href="https://fonts.googleapis.com/css?family=Kaushan+Script|Source+Sans+Pro"
        rel="stylesheet"
      />
    </>

  )
}

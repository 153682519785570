import React from 'react'
import { HRAboutInstructor, HRTeamOne, HRTeamThree, HRTeamTwo } from '../courses/data/HRContent'
import { AboutMentor } from './AboutMentor'
import { usePageMetadata } from '../hooks/usePageMetadata '

export const TrainerProfile = () => {
  
  const title = "HR Trainer Profile";
  const description = "Your page description goes here.";
  const keywords = "keyword1, keyword2, keyword3";

  usePageMetadata(title, description, keywords);
  return (
    <>
    <AboutMentor data={HRTeamOne} />
    <AboutMentor data={HRTeamTwo} />
    <AboutMentor data={HRTeamThree} />
    </>
  )
}

import React from 'react'
import './component/style.css'
import { CourcesAbout, CourcesAbout_Instructor, CourcesForm, CourcesJavascript, DayaAbout } from './component'
import { JSAbout, JSAboutInstructor, JSCourse, JSForm } from './data/JSContent'
import { usePageMetadata } from '../hooks/usePageMetadata '

export const JsCource = () => {
  const title = "Javascript Certification";
  const description = "Your page description goes here.";
  const keywords = "keyword1, keyword2, keyword3";

  usePageMetadata(title, description, keywords);
  return (
    <>
      <CourcesForm data={JSForm} />
      <CourcesJavascript data={JSCourse}/>
      <CourcesAbout data={JSAbout} />
      <CourcesAbout_Instructor data={JSAboutInstructor} />
      <DayaAbout data={JSForm} />
    </>
  )
}

import React from 'react'
import { usePageMetadata } from '../hooks/usePageMetadata ';
import { CourcesAbout, CourcesAbout_Instructor, CourcesForm, CourcesJavascript, DayaAbout } from './component';
import { PythonAbout, PythonAboutInstructor, PythonCourse, PythonForm } from './data/PythonContent';

export const Python_course = () => {
    const title = "Python Certification";
    const description = "Your page description goes here.";
    const keywords = "keyword1, keyword2, keyword3";
    usePageMetadata(title, description, keywords);

    return (
        <>
        <CourcesForm data={PythonForm} />
        <CourcesJavascript data={PythonCourse}/>
        <CourcesAbout data={PythonAbout} />
        <CourcesAbout_Instructor data={PythonAboutInstructor} />
        <DayaAbout data={PythonForm} />
      </>
    )
}

import React, { useEffect } from 'react'
import "./loadingPage.css"
import axios from 'axios'
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePageMetadata } from '../hooks/usePageMetadata ';

export const LoadingPage = () => {
    const title = "Loading Page";
    const description = "Your page description goes here.";
    const keywords = "keyword1, keyword2, keyword3";

    usePageMetadata(title, description, keywords);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const merchantTransactionId = localStorage.getItem("merchantTransactionId");
    console.log(`${BACKEND_URL}/api/phonepe/status/${merchantTransactionId}`)
    const navigate = useNavigate();

    // ...

    useEffect(() => {
        const fetchData = async () => {
            let loadingToast;
            try {
                // Display loading state
                loadingToast = toast.loading("Fetching user data...", {
                    position: toast.POSITION.TOP_RIGHT,
                });

                const response = await axios.post(`${BACKEND_URL}/api/phonepe/status/${merchantTransactionId}`);

                if (response.status === 200) {
                    const data = response.data;
                    // console.log(data.data.payment_status)
                    if (data.data.payment_status === "PAYMENT_SUCCESS") {
                        navigate("/thankyou");
                        localStorage.removeItem("merchantTransactionId");

                    } else {
                        navigate("/payment_failed")
                        localStorage.removeItem("merchantTransactionId");

                    }
                } else {
                    // Handle non-OK response
                    toast.error("Failed to fetch data", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    localStorage.removeItem("identity");

                    navigate("/")
                }
            } catch (err) {
                // Handle errors
                alert("Unable to get data", err);
                localStorage.removeItem("identity");
                navigate("/")

                // localStorage.removeItem("identity")
            } finally {
                // Close the loading state
                if (loadingToast) {
                    toast.dismiss(loadingToast);
                }
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="preloader">

                <svg
                    className="cart"
                    role="img"
                    aria-label="Shopping cart line animation"
                    viewBox="0 0 128 128"
                    width="128px"
                    height="128px"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={8}>
                        <g className="cart__track" stroke="hsla(0,10%,10%,0.1)">
                            <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                            <circle cx={43} cy={111} r={13} />
                            <circle cx={102} cy={111} r={13} />
                        </g>
                        <g className="cart__lines" stroke="currentColor">
                            <polyline
                                className="cart__top"
                                points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                                strokeDasharray="338 338"
                                strokeDashoffset={-338}
                            />
                            <g className="cart__wheel1" transform="rotate(-90,43,111)">
                                <circle
                                    className="cart__wheel-stroke"
                                    cx={43}
                                    cy={111}
                                    r={13}
                                    strokeDasharray="81.68 81.68"
                                    strokeDashoffset="81.68"
                                />
                            </g>
                            <g className="cart__wheel2" transform="rotate(90,102,111)">
                                <circle
                                    className="cart__wheel-stroke"
                                    cx={102}
                                    cy={111}
                                    r={13}
                                    strokeDasharray="81.68 81.68"
                                    strokeDashoffset="81.68"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
                <div className="preloader__text">
                    <p className="preloader__msg">Checking your payment status.Don't go back. Keep patience</p>

                    <p className="preloader__msg preloader__msg--last">
                        This is taking long. Something’s wrong.
                    </p>
                </div>
                <ToastContainer />
            </div>

        </>
    )
}

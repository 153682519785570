import React, { useContext, useEffect } from "react";
import "./admin.css";
import { useState } from "react";
import AuthContext from "../context/AuthContext";
// import { BACKEND_URL } from '../config/constraints';
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { UpdateUser } from "./forms/UpdateUser";
import retrive from "../assets/retrive.svg";
import dustbin from "../assets/dustbin.png";
import { DeleteUser } from "./forms/DeleteUser";
import search from "../assets/search-v1.png";
import edit from "../assets/edit.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import register from "../images/registration.png";
import { RegisterUser } from "./forms/RegisterUser";
import { usePageMetadata } from "../hooks/usePageMetadata ";

export const Transaction = () => {
  const title = "Transactions";
  const description = "Your page description goes here.";
  const keywords = "keyword1, keyword2, keyword3";

  usePageMetadata(title, description, keywords);
  const navigate = useNavigate();
  const [showRegsForm, setRegsForm] = useState(false);
  const [showDeleteForm, setDeleteForm] = useState(false);
  const [name, setName] = useState("");

  const [uname, setUName] = useState("");
  const [email, setEmail] = useState("");
  const [isId, setId] = useState("");

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const handelRegis = (value) => {
    // setId(value);

    setUName(value.name);
    setEmail(value.email);
    setId(value._id);
    // console.log(value.email)
    setRegsForm(true);
  };

  const { role, success, setIsLoggedIn, errorHandleLogout } =
    React.useContext(AuthContext);

  const token = localStorage.getItem("token");
  // console.log(token)
  if (!token && role !== "admin" && success !== true) {
    <Navigate to="/login" />;
  }
  const [users, setUsers] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);

  const [searchText, setSearchText] = useState("");

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchText.toLowerCase()) ||
      user.email.toLowerCase().includes(searchText.toLowerCase())
  );

  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    let loadingToast;

    const fetchData = async () => {
      try {
        // Display loading state
        loadingToast = toast.loading(
          "Fetching unregistered succesfull transaction...",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        const response = await fetch(
          `${BACKEND_URL}/api/phonepe/all_transactions/unregistered`,
          {
            headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          const filteredUsers = data.data.filter(
              (user) =>
                  user.payment_status &&
                  user.payment_status === "PAYMENT_SUCCESS" &&
                  user.name.toLowerCase().includes(searchText.toLowerCase())
          );
      
          // Sorting logic based on timestamp (createdAt or updatedAt)
          filteredUsers.sort((a, b) => {
              const timestampA = new Date(a.createdAt || a.updatedAt).getTime();
              const timestampB = new Date(b.createdAt || b.updatedAt).getTime();
              return timestampB - timestampA;
          });
      
          // Set the filtered users
          setUsers(filteredUsers);
          setName("UnRegs Successful");
      }
       else {
          // Handle non-OK response
          toast.error("Failed to fetch course data", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (err) {
        // Handle errors
        alert("Session Timeout", err);
        errorHandleLogout();
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    };

    fetchData();
  }, [, refresh]);

  const handleUnregisteredSuccesfullUsers = (e) => {
    e.preventDefault();
    // axios.get(`${BACKEND_URL}/api/phonepe/transaction/registered `, {headers})
    let loadingToast;
    const fetchData = async () => {
      try {
        // Display loading state
        loadingToast = toast.loading(
          "Fetching unregistered succesfull transaction...",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        const response = await fetch(
          `${BACKEND_URL}/api/phonepe/all_transactions/unregistered`,
          {
            headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log(data.data)
          const filteredUsers = data.data.filter(
            (user) =>
              user.payment_status &&
              user.payment_status === "PAYMENT_SUCCESS" &&
              user.name.toLowerCase().includes(searchText.toLowerCase())
          );

          // Set the filtered users
          setUsers(filteredUsers);
          setName("UnRegs Successfull");
        } else {
          // Handle non-OK response
          toast.error("Failed to fetch course data", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (err) {
        // Handle errors
        alert("Session Timeout", err);
        errorHandleLogout();
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    };

    fetchData();
  };
  const handleUnregisteredFailedUsers = (e) => {
    e.preventDefault();
    // axios.get(`${BACKEND_URL}/api/phonepe/transaction/registered `, {headers})
    let loadingToast;
    const fetchData = async () => {
      try {
        // Display loading state
        loadingToast = toast.loading(
          "Fetching unregistered failed transaction...",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        const response = await fetch(
          `${BACKEND_URL}/api/phonepe/all_transactions/unregistered`,
          {
            headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log("original data", data.data)
          const filteredUsers = data.data.filter(
            (user) =>
              user.payment_status !== "PAYMENT_SUCCESS" && !user.transactionId
          );

          // Set the filtered users
          setUsers(filteredUsers);
          // console.log("filteredUsers", filteredUsers)
          setName("UnRegs Failed");
        } else {
          // Handle non-OK response
          toast.error("Failed to fetch course data", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (err) {
        // Handle errors
        alert("Session Timeout", err);
        errorHandleLogout();
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    };

    fetchData();
  };

  const handleRegisteredSuccesfullUsers = (e) => {
    e.preventDefault();
    // axios.get(`${BACKEND_URL}/api/phonepe/transaction/registered `, {headers})
    let loadingToast;
    const fetchData = async () => {
      try {
        // Display loading state
        loadingToast = toast.loading(
          "Fetching registered succesfull transaction...",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        const response = await fetch(
          `${BACKEND_URL}/api/phonepe/all_transactions/registered`,
          {
            headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log(data.data)
          const filteredUsers = data.data.filter(
            (user) =>
              user.payment_status && user.payment_status === "PAYMENT_SUCCESS"
          );

          // Set the filtered users
          setUsers(filteredUsers);
          setName("Regs Successfull");
        } else {
          // Handle non-OK response
          toast.error("Failed to fetch course data", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (err) {
        // Handle errors
        alert("Session Timeout", err);
        errorHandleLogout();
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    };

    fetchData();
  };
  const handleRegisteredFailedUsers = (e) => {
    e.preventDefault();
    // axios.get(`${BACKEND_URL}/api/phonepe/transaction/registered `, {headers})
    let loadingToast;
    const fetchData = async () => {
      try {
        // Display loading state
        loadingToast = toast.loading(
          "Fetching registered failed transaction...",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        const response = await fetch(
          `${BACKEND_URL}/api/phonepe/all_transactions/registered`,
          {
            headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log("original data", data.data)
          const filteredUsers = data.data.filter(
            (user) => user.payment_status !== "PAYMENT_SUCCESS"
          );

          // Set the filtered users
          setUsers(filteredUsers);
          // console.log("filteredUsers", filteredUsers)
          setName("Regs Failed");
        } else {
          // Handle non-OK response
          toast.error("Failed to fetch course data", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (err) {
        // Handle errors
        alert("Session Timeout", err);
        errorHandleLogout();
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    };

    fetchData();
  };

 
  

  return (
    <div className="adminContent">
      <div className="div1">
        <div className="div2">
          <div className="all">
            <h1>{name} Transactions</h1>
            <div className="allInner">
              <div className="search-box">
                <button class="btn-search">
                  <img width="35" height="35" src={search} alt="search--v1" />
                </button>

                <input
                  type="text"
                  className="input-search"
                  placeholder="Filter User ..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>

              <div className="buttons">
                <button onClick={handleUnregisteredSuccesfullUsers}>
                  UnRegs Successfull{" "}
                </button>
                <button onClick={handleUnregisteredFailedUsers}>
                  {" "}
                  UnRegs Failed
                </button>
                <button onClick={handleRegisteredSuccesfullUsers}>
                  {" "}
                  Regs Successfull
                </button>
                <button onClick={handleRegisteredFailedUsers}>
                  {" "}
                  Regs Failed
                </button>
              </div>
            </div>

            <div className="tableContent">
              <table className="table">
                <thead style={{ borderBottomWidth: "1px" }}>
                  <th>Sl. No.</th>
                  <th>Candidate Name</th>
                  <th>Email</th>
                  <th>Phone No</th>
                  <th>Date & Time</th>
                  <th>Amount</th>
                  <th>M. Trans. Id</th>
                  <th>Trans. Id</th>
                  <th>Register</th>
                </thead>
                <tbody>
                  {/* * Renders a table row for each item in the filteredUsers array.
                   * @param {Array} filteredUsers - An array of user objects to render in the table.
                   * @returns The rendered table rows. */}

                  {filteredUsers.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.number}</td>
                      <td>{new Date(item.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</td>
                      <td>{item.amount}</td>
                      <td>{item.merchantTransactionId}</td>
                      <td>{item.transactionId}</td>
                      <td>
                        <img
                          onClick={() => handelRegis(item)}
                          src={register}
                          alt="register"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showRegsForm ? (
        <RegisterUser
          setRegsForm={setRegsForm}
          uname={uname}
          email={email}
          isId={isId}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ) : (
        ""
      )}
      <ToastContainer autoClose={2500} />
    </div>
  );
};

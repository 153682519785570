import React from 'react'
import images from '../courses/images/three.png'
import "../courses/css/style.css"
import arrow from "../images/arrow_24.png"

export const AboutMentor = ({ data }) => {
    return (
        <>



            <section className='sec_area'>
                <div className='container-fluid'>
                    <div className='row content'>
                        <div className="team-top-bg">
                            <img src={images} alt="images" />
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="team-three-image">
                                    <img className='pin2' src={data.img} alt='pin2' />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="team-three-top-wrapper">
                                    <div className="common-title team3-title">
                                        <h3>{data.name}</h3>
                                        <h5>{data.designation}</h5>
                                    </div>
                                    <h6 style={{ color: 'black' }}>About:</h6>

                                    {Object.values(data.list).map((item, index) => (
                                        <li key={index}>
                                            <img src={arrow} alt="arrow" />
                                            {item}
                                        </li>
                                    ))}
                                    <p>{data.slogan}</p>
                                    {
                                        data.highlights &&
                                        <>
                                            <h6>Highlights:</h6>
                                            {
                                                Object.values(data.highlights).map((item, index) => (
                                                    <li key={index}>
                                                        <img src={arrow} alt="arrow" />
                                                        {item}
                                                    </li>
                                                ))
                                            }
                                        </>


                                    }
                                    {
                                        data.exp && (
                                            <p>
                                                <strong>Experience: </strong> {data.exp} Years
                                            </p>
                                        )
                                    }


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}


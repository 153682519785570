import image1 from "../images/htmlbanner.jpg";
import tagg from "../images/tagg9.png";
import subha from "../images/avi.png";
import video from "../videos/html_css.mp4"
export const HTMLForm = {
    img: image1,
    tagg: tagg,
    amount:9,
    courseName:"html/css",
    quote:"Exclusive! Limited Slots Available. Join Now. Starting Date ➡ 17 February, 2024"
};

export const HTMLCSSAbout = {
    title: "HTML / CSS",
    one: {
        title: "HTML / CSS",
        content:
            "Learn the fundamentals of HTML and CSS, the building blocks of web development.",
    },
    two: {
        courseDetails: {
            Instructor: "Avinash Kumar Sondi",
            Duration: "14 hours Live Session",
            AverageDuration: "1 month",
            Level: "Beginner To Intermediate",
            FreeOrPaid: "Paid",
            Fees: "9/-",
            Certificate: "Yes",
        },
    },
    three: {
        courseFacilities: {
            one: "Hands-on projects to enhance your skills",
            
        },
    },
    four: {
        courseContent: {
            one: "HTML Basics and Structure",
            two: "CSS Styling and Layouts",
            three: "Responsive Web Design",
            four: "CSS Flexbox",
            five: "CSS Transitions and Animations",
            six: "Best Practices and Optimization",
        },
    },
};

export const HTMLCSSCourse = {
    title: "HTML/CSS Course",
    cousrseDetails: {
        duration: "14 hours on-demand video",
        articles: 3,
        downloadResources: 1,
    },
    requirements: {
        one: "No coding experience is required! We will start from the very basics and take you to an intermediate level!",
        two: "You just need a computer, and a web browser",
        three: "Enthusiasm to learn and create web pages with HTML and CSS",
    },
    videoPath:video
};

export const HTMLCSSAboutInstructor = {
    img: subha,
    name: "Avinash Kumar Sondi",
    designation: "UI / UX Designer",
    about: "Greetings, I am Avinash Kumar Sondi, an alumnus of IIT Hyderabad with a degree in B.Tech Computer Science & Engineering. I bring more than 5 years of hands-on industry experience, excelling in UI/UX Design and Development, with a strong command of HTML and CSS.",
    exp: 5,
};

import React, { useContext, useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../login/forgo.css"
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import axios from 'axios';



export const DeleteUser = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const SECRET_KEY_CAPTCHA = process.env.REACT_APP_SECRET_KEY_CAPTCHA;
    const { errorHandleLogout, setIsLoggedIn } = useContext(AuthContext);
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(true);
    const userID = localStorage.getItem("userId")
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const handleCaptchaChange = (value) => {
        // This callback will be triggered when the user completes the reCAPTCHA challenge
        setIsCaptchaVerified(true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(postData);
        if (isCaptchaVerified) {
            axios.delete(`${BACKEND_URL}/users/temp_delete_user/${userID}`, { headers: headers })
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    // Assuming the server returns the OTP in the response
                    if (res.data.success === true) {
                        toast.success("Account is deleted successfully!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        errorHandleLogout();
                        setIsLoggedIn(false);
                        navigate("/");

                    }
                } else {
                    toast.error("Unable to delete!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((err) => {
                alert("Session Timeout", err);
                errorHandleLogout();
                setIsLoggedIn(false);
                navigate("/");
            });
        }
        else {
            toast.error("Please complete the reCAPTCHA challenge!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }
    return (
        <div className="userDelete">
            <div className="deleteUser">

                <h3>Are you sure you want to delete the Account</h3>
                <ReCAPTCHA
                    sitekey={SECRET_KEY_CAPTCHA}
                    onChange={handleCaptchaChange}
                />
                <div style={{ display: "flex", flexDirection: "row", columnGap: "4rem" }}>
                    <button type="button" className="delete1" style={{ alignItems: 'center' }} onClick={handleSubmit}>
                        Delete Account
                    </button>
                    <Link to="/userPage">
                        <button type="button" className="back_button">
                            Go Back
                        </button>
                    </Link>

                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

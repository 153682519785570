import "./App.css";
import React, { useEffect, useHistory } from "react";
import axios from "axios";
import { AllRoutes } from "./routes/AllRoutes";
import { AuthContextProvider } from "./context/AuthContext";
import ReactGA from "react-ga"
import initializeTagManager from "./GTM";

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
const GTM_ID = process.env.REACT_APP_GTM_ID;
const GTAG = process.env.REACT_APP_GTAG;
const ADS_SCRIPT = process.env.REACT_APP_ADS_SCRIPT;
ReactGA.initialize(TRACKING_ID);
function App() {
  useEffect(() => {
    initializeTagManager()
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-${GTAG}');
    `;
    document.head.appendChild(script);

    const gtmScript = document.createElement("script");
    gtmScript.innerHTML = `
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${GTM_ID}');
  `;
    document.head.appendChild(gtmScript);


    // Load Google Ads Conversion Tracking script
    const adsScript = document.createElement("script");
    adsScript.async = true;
    adsScript.src = ADS_SCRIPT;
    adsScript.onload = () => {
      // This callback ensures that the script has been loaded before using the 'AW' variable
      // Track initial pageview
      // Assuming you have imported ReactGA
      ReactGA.pageview(window.location.pathname + window.location.search);

    };
    document.head.appendChild(adsScript);

    // Track initial pageview
  }, []);
  return (
    <AuthContextProvider>

      <AllRoutes />
    </AuthContextProvider>


  );
}

export default App;

import React, { useEffect } from 'react'
import "./admin.css"
import { Fragment } from 'react'
import { useState } from 'react'
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import menu from "../assets/squared-menu.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

export const Admin = () => {
  /**
   * Checks if a user is authenticated and has the necessary role to access a specific route.
   * If the user is not authenticated or does not have the required role, it redirects them to the login page.
   * @returns None
   */
  const { setIsLoggedIn, isLoggedIn } = React.useContext(AuthContext);
  const token = localStorage.getItem("token")
  const [toggle, setToggle] = useState(false);
  const role = localStorage.getItem("role")
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is not logged in
    if (!token) {
      // Redirect to the login page if not logged in
      navigate("/login");
      return;
    }

    // Check if the role is admin

    // If the role is user, you can redirect to the user page
    if (role === "user") {
      navigate("/userPage");
      return;
    }

    // If the role is neither admin nor user, handle it as needed
    // For example, you might want to show an error message or redirect to a default page

    // Additional logic inside useEffect
   
  }, [token, role, navigate]); // Dependency array includes variables used inside useEffect

useEffect(()=>{
  if (token && role === "admin") {
    toast.success("Login Successfully !", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
},[token,role])
  /**
   * Toggles the state of the dropdown menu.
   * @returns None
   */
  const dropDownMenu = () => {
    setToggle(!toggle)
  }


  return (
    <Fragment>
      <div className="admin">
        <div className="adminHeader">
          <p onClick={dropDownMenu}>
            <img width="30"
              height="30"
              style={{ cursor: "pointer" }}
              src={menu} alt="squared-menu" />
          </p>
          <h1>Welcome to admin page</h1>
        </div>
        <div className='sidebar'>

          {/* * Renders a sidebar menu based on the value of the toggle variable.
           * @param {boolean} toggle - Determines whether the sidebar menu should be displayed or not.
           * @param {function} handleOptionClick - Callback function to handle the click event on each menu option.
           * @returns The rendered sidebar menu component. */}

          {
            toggle ?
              <div className="sidebar-menu">
                {/* <p onClick={() => handleOptionClick('Logout')}>Logout</p> */}
                <p>
                  <NavLink to="/admin/add_user" onClick={() => setToggle(!toggle)}>
                    Add User
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/admin/all_users" onClick={() => setToggle(!toggle)}>
                    All Users
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/admin/upload" onClick={() => setToggle(!toggle)}>
                    Add Course Videos
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/admin/all_courses" onClick={() => setToggle(!toggle)}>
                    All Courses
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/admin/deleted_courses" onClick={() => setToggle(!toggle)}>
                    Deleted Courses
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/admin/transactions" onClick={() => setToggle(!toggle)}>
                    Transactions
                  </NavLink>
                </p>

              </div>
              : ''
          }
        </div>

        <ToastContainer />
      </div>
    </Fragment>
  )
}

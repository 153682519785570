import React from 'react'
import { usePageMetadata } from '../hooks/usePageMetadata ';

import './component/style.css'
import { CourcesAbout, CourcesAbout_Instructor, CourcesForm, CourcesJavascript, DayaAbout } from './component'
import { TestingAbout, TestingAboutInstructor, TestingForm,TestingCours } from './data/TestingData'


export const TestingCourse = () =>{
    const title = "Testing Certification";
    const description = "Your page description goes here.";
    const keywords = "keyword1, keyword2, keyword3";
    usePageMetadata(title, description, keywords);

    return (
        <>
            <CourcesForm data={TestingForm} />
            <CourcesJavascript data={TestingCours} />
            <CourcesAbout data={TestingAbout} />
            <CourcesAbout_Instructor data={TestingAboutInstructor} />
            <DayaAbout data={TestingForm} />
        </>
    )
}

import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./style.css";
import "./mediaScreen.css";
import "../component/mediaScreen.css";
import { useNavigate } from "react-router-dom";
// import { BACKEND_URL } from "../../config/constraints";
// import { PHONE_PE_URL } from "../../config/constraints";
// import { EmailContext } from "../../context/EmailContext";


const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string()
        .matches(/^[6789]\d{9}$/, "Please enter a 10-digit Indian mobile number (without +91) starting with 6, 7, 8, or 9")
        .required("Phone number is required"),
});


export const CourcesForm = ({ data }) => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const PHONE_PE_URL = process.env.REACT_APP_PHONE_PE_URL;
    // const { updateEmail } = useContext(EmailContext);
    // console.log(data.amount)
    const navigate = useNavigate();

    function generateTransactionID() {
        const timestamp = Date.now();
        const randomNum = Math.floor(Math.random() * 1000000);
        const merchantPrefix = "T";
        const transactionID = `${merchantPrefix}${timestamp}${randomNum}`;
        return transactionID;
    }

    // Assuming this code is within a function or a component
    const merchantTransactionId = generateTransactionID();
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phoneNumber: "",
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const postData = {
                name: values.name,
                email: values.email,
                phone: values.phoneNumber,
                merchantTransactionId: merchantTransactionId,
            };
            localStorage.setItem("merchantTransactionId", merchantTransactionId)

            console.log(postData);
            try {
                await axios.post(`${BACKEND_URL}/api/phonepe/payment`, {
                    data: {
                        name: values.name,
                        number: values.phoneNumber,
                        email: values.email,
                        amount: data.amount,
                        merchantTransactionId: merchantTransactionId,
                        course: data.courseName
                    },
                })
                    .then((response) => {
                        // Do something with the response from the second API
                        window.location.href = response.data;
                    })
                    .catch((error) => {
                        alert("Error fetching the second API:", error);
                    });
            } catch (error) {
                alert("error", error);
            }
            resetForm();
        },
    });
    return (
        <section className="home-three-banner">
            <div className="banner-image">
                <div className="container-fluid">
                    <div className="row_home">
                        <div className="col-lg-7 banner-inner">
                            <img src={data.img} alt="images" />

                            <marquee
                                id="mar_scroll"
                                width="100%"
                                height="100px"
                                direction="left"
                            >
                                {data.quote}
                            </marquee>
                        </div>

                        <div className="fom_header">
                            <h2 style={{ color: "#000" }}>Subscribe to SOLP’s courses</h2>
                            <form onSubmit={formik.handleSubmit}>
                                <div>
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="error">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="error">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor="phoneNumber">Pn.No.</label>
                                    <input
                                        type="tel"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        onChange={formik.handleChange}
                                        value={formik.values.phoneNumber}
                                    />
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                        <div className="error">{formik.errors.phoneNumber}</div>
                                    ) : null}
                                </div>

                                <div className="col-lg-6 col-md-6 row_col">
                                    <button className="btn-success" type="submit">
                                        Submit
                                    </button>
                                </div>
                                {/* <div className="tag_row price" style={{position:"relative"}}>
                                    <img src={data.tagg} alt="Formtagg" className="formtagg" />
                                    <p id="price">1049/-</p>
                                </div> */}
                                <div className="formtagg">
                                    <p id="price">{data.amount}/-</p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};


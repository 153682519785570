import React, { useEffect } from 'react';

export const usePageMetadata = (title, description, keywords) => {
  useEffect(() => {
    // Update document title
    if (title === " ") {
      document.title = `${title}Steps Of Learning Questions`;
    } else {
      document.title = `${title} - Steps Of Learning Questions`;
    }

    // Update meta description
    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', description);
    }

    // Update meta keywords
    const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
    if (metaKeywordsTag) {
      metaKeywordsTag.setAttribute('content', keywords);
    }
  }, [title, description, keywords]);

  return null;
};

import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../services/css/counselling.css";
import "../../services/css/mediaScreen.css";
import MultiSelectDropdown from "../../layouts/MultiSelectDropdown";
import AuthContext from "../../context/AuthContext";
// import { BACKEND_URL } from "../config/constraints";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { InactivityTimer } from "../../layouts/InactivityTimer";
import delete_sign from "../../assets/delete_sign.png"


const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("First Name is required"),

  Email: Yup.string()
    .email("Invalid Email format")
    .required("Email is required"),
  ConfirmEmail: Yup.string()
    .email("Invalid Email format")
    .required("Email confirmation is required")
    .oneOf([Yup.ref('Email')], "Emails do not match"),

});

export const UpdateUser = ({ setUpdateForm, isId }) => {
  // console.log("User ID", isId)
  const { userId } = React.useContext(AuthContext);
  // console.log("USER ID:",userId)

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [selectedOption, setSelectedOption] = React.useState('');


  const handleClose = () => {
    setUpdateForm(false);
  }
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const { role, success, errorHandleLogout, setIsLoggedIn } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  if (token && role !== "admin" && success === true) {
    <Navigate to="/login" />
  }
  const options = ['1) HTML', '2) CSS', '3) JAVASCRIPT', '4) REACT JS'];
  const [selectedValues, setSelectedValues] = useState([]);
  const [gpassword, setgPassword] = useState("");
  const handleSelectionChange = (newSelection) => {
    setSelectedValues(newSelection);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleConPasswordVisibility = () => {
    setShowConPassword((prevShowPassword) => !prevShowPassword);
  };
  const initialValues = {
    Name: "",
    Email: "",
    password: gpassword,

  };
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [isHTML, setIsHTML] = useState(false);
  const [isCSS, setIsCSS] = useState(false);
  const [isJS, setIsJS] = useState(false);
  const [isReact, setIsReact] = useState(false);
  const [isNode, setIsNode] = useState(false);
  const [isPython, setIsPython] = useState(false);
  const [isJava, setIsJava] = useState(false);
  const [isTesting, setISTesting] = useState(false);
  const [isSoftSkill, setISSOftSkill] = useState(false);

  const [apiResponse, setApiResponse] = useState({});
 
  useEffect(() => {
    const fetchData = async () => {
      // Display loading state
      const loadingToast = toast.loading("Fetching user profile...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      try {
        const response = await axios.get(`${BACKEND_URL}/users/profile/${isId}`, {
          headers: headers,
        });

        const data = response.data.user;
        setgPassword(data.password);
        setApiResponse({
          Name: data.name,
          Email: data.email,
          password: gpassword,
        });
        setIsHTML(data.isHTML);
        setIsCSS(data.isCSS);
        setIsJS(data.isJS);
        setIsNode(data.isNODE);
        setIsReact(data.isREACT);
        setIsPython(data.isPYTHON);
        setIsJava(data.isJAVA);
        setISTesting(data.isTESTING);
        setISSOftSkill(data.isSOFTSKILL);

        toast.success("Profile data fetched successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (err) {
        // Handle errors
        alert("Session Timeout", err);
        errorHandleLogout();
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    };

    fetchData();
  }, []);


  const handleSubmit = async (values, { resetForm }) => {
    const postData = {
      name: values.Name,
      email: values.Email,
      password: gpassword,
      isHTML: isHTML,
      isCSS: isCSS,
      isJS: isJS,
      isREACT: isReact,
      isNODE: isNode,
      isPYTHON: isPython
    };

    // Display loading state
    const loadingToast = toast.loading("Updating details...", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

    try {
      const response = await axios.post(`${BACKEND_URL}/users/update_user/${isId}/${userId}`, postData, { headers: headers });

      if (response.data.success) {
        toast.success("Details Updated successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setgPassword("");
        resetForm();
      } else {
        toast.error("Details Update Failed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (err) {
      // Handle errors
      alert("Session Timeout", err);
      errorHandleLogout();
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const generatePassword = () =>
    setgPassword(
      Array.from(
        { length: 8 },
        () =>
          "abcdefghijklmnopqrstuvwxyz0123456789"[
          Math.floor(Math.random() * 62)
          ]
      ).join("")
    );

  return (
    <>
      <InactivityTimer />
      <div className="mainCover">
        <div className="cover">
          <div className="c-small">
            <div className="containerCoun">
              <Formik
                initialValues={apiResponse}
                enableReinitialize
                // validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values }) => (
                  <Form className="form">
                    <div className="heading">
                      <h2 style={{ color: "white" }}>Update User</h2>
                      <img width="64" height="64" src={delete_sign} alt="delete-sign" onClick={handleClose}></img>
                    </div>
                    <div className="project">
                      <label htmlFor="Name">
                        Full Name
                        <Field
                          type="text"
                          id="Name"
                          placeholder="Enter your full name"
                          name="Name"
                        />
                      </label>
                      <ErrorMessage name="Name" className="error" component="div" />
                    </div>
                    <div className="project">
                      <label htmlFor="Email">
                        Email Address
                        <Field
                          type="text"
                          id="Email"
                          placeholder="Enter your Email"
                          name="Email"
                        />
                      </label>
                      <ErrorMessage
                        name="Email"
                        className="error"
                        component="div"
                      />
                    </div>

                    <h3>Assign Course</h3>
                    <div className="choose_course" style={{ marginBottom: "0" }}>

                      <input
                        type="checkbox"
                        name="isHTML"
                        checked={isHTML}
                        onChange={() => setIsHTML(!isHTML)}
                      />
                      HTML
                      <input
                        type="checkbox"
                        name="isCSS"

                        checked={isCSS}
                        onChange={() => setIsCSS(!isCSS)}
                      />
                      css
                      <input
                        type="checkbox"
                        name="isJS"
                        checked={isJS}
                        onChange={() => setIsJS(!isJS)}
                      />
                      JavaScript
                      <input
                        type="checkbox"
                        name="isReact"
                        checked={isReact}
                        onChange={() => setIsReact(!isReact)}
                      />
                      React
                      <input
                        type="checkbox"
                        name="isNode"
                        checked={isNode}
                        onChange={() => setIsNode(!isNode)}
                      />
                      Node
                    </div>
                    <div className="choose_course" >
                      <label htmlFor="isPython">
                        <input
                          type="checkbox"
                          name="isPython"
                          checked={isPython}
                          onChange={() => setIsPython(!isPython)}
                        />
                        Python</label>
                        <label htmlFor="isJava">
                    <input
                      type="checkbox"
                      name="isJava"
                      checked={isJava}
                      onChange={() => setIsJava(!isJava)}
                    />
                    JAVA</label>
                  <label htmlFor="isTesting">
                    <input
                      type="checkbox"
                      name="isTesting"
                      checked={isTesting}
                      onChange={() => setISTesting(!isTesting)}
                    />
                    Testing</label>
                  <label htmlFor="isSoftSkill">
                    <input
                      type="checkbox"
                      name="isSoftSkill"
                      checked={isSoftSkill}
                      onChange={() => setISSOftSkill(!isSoftSkill)}
                    />
                    Soft Skill</label>
                    </div>


                    <div className="passForm">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label htmlFor="password">
                          Password:
                          <Field
                            id="password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            value={gpassword}
                          />
                          <i
                            className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"
                              }`}
                            aria-hidden="true"
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </label>
                        <button
                          type="button"
                          style={{
                            color: "white",
                            border: "1px solid white",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "30px",
                            backgroundColor: "#00715d",
                          }}
                          onClick={generatePassword}
                        >
                          Generate Password
                        </button>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error"
                        />
                      </div>


                    </div>

                    <button type="submit" className="submit">
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={2500}/>
      </div>
    </>
  );
};

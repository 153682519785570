import image1 from "../images/python.jpg";
import tagg from "../images/tagg.png";
import pin2 from '../images/python_tutor.jpeg'
import video from "../videos/python.mp4"
export const PythonForm={
    img: image1,
    tagg: tagg,
    amount: 299,
    courseName:"Python",
    quote:"Exclusive! Limited Slots Available. Join Now.  Starting Date ➡ 18 February, 2024"
}

export const PythonAbout={
    title: "Python",
    one: {
        title: "PYTHON",
        content: "In web development, Python is commonly used on the server side to handle the back-end logic, while HTML and CSS are used on the client side for structuring and styling the web pages."
    },
    two:{
        courseDetails: {
            Instructor: 'Vishak H. Pillai',
            Duration: '16 hours Live Session (Weekend only)',
            AverageDuration: '1 month',
            Level: 'Beginner To High',
            FreeOrPaid: 'Paid',
            Fees: '299/-',
            Certificate: 'Yes',
          },
    },
    three:{
        courseFacilities:{
            one: 'Projects to add to your portfolio',
            two: 'Bonus section on Git & GitHub , basic information on HTML & CSS',
            three: 'Lesson on solving interview questions with Python',
        }
    },
    four:{
        courseContent:{
            one: 'Python Fundamentals',
            two: 'Python Program Flow',
            three: 'Functions& Modules',
            four: 'ExcepƟons Handling',
            five: 'Classes In Python ',
            six: 'Data Structures'
        }
    }
}

export const PythonCourse={
    title: "Python Course",
    cousrseDetails:{
        duration: "16.5 hours on-demand video",
        articles: 3,
        downloadResources: 1
    },
    requirements:{
        one: 'You need access to a computer, which can run Windows, macOS, or Linux.',
        two: 'Install the Python interpreter on your computer. You can download it from the official Python website',
        three: 'Choose and set up a code editor. Options include Visual Studio Code, PyCharm, Sublime Text, Atom, etc.'
    },
    videoPath:video
}

export const PythonAboutInstructor={
    img: pin2,
    name: "Vishak H. Pillai",
    designation: "University Mathematics/Statistics/Computer science coach",
    about: "Greetings, My name is Vishak H. Pillai, and I am a mathematics and statistics master's degree holder. I attended the Indian Institute of Technology, Tirupati. Over the course of my eight years in the workforce, I have held significant positions in the business sector and developed strong skills in software development and data engineering. In addition to my professional endeavours, I am passionate about teaching and am an expert in Python.",
    exp: 8,
}


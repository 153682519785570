import React from 'react'
import { Link } from 'react-router-dom'
import notify from '../images/notify.jpg'
import { usePageMetadata } from '../hooks/usePageMetadata ';

export const UnderDevelopment = () => {
    const title = "Under Development";
    const description = "Your page description goes here.";
    const keywords = "keyword1, keyword2, keyword3";

    usePageMetadata(title, description, keywords);
    return (
        <div className='under_development'>
            <img className='notify' src={notify} alt='notify' />
            {/* <h4>Please Go Back to
                <span>
                    <Link to="/">Home Page</Link>
                </span>
            </h4> */}
        </div>
    )
}

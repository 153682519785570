// GTM.js
import TagManager from 'react-gtm-module';

const initializeTagManager = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-52Q9QTND', // Replace with your GTM container ID
  };

  TagManager.initialize(tagManagerArgs);
};

export default initializeTagManager;

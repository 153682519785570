import React from 'react'
import "./css/tour.css"

export const Tour = ({setStartTour,setShowPopup}) => {

    return (
        <div className='rock'>
            <div className="body">
                <p>Do You want to take a tour</p>
                <div className="button">
                    <button class="bn54" onClick={()=>{
                        setStartTour(true);
                        setShowPopup(false);
                    }}>
                        <span class="bn54span">Yes</span>
                    </button>
                    <button class="bn54" style={{backgroundColor:"#d9a9a2"}} onClick={()=>setShowPopup(false)}>
                        <span class="bn54span">No</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './navbar.css';
import { FaBars } from 'react-icons/fa';
// import { ImCross } from 'react-icons/im';
import logo from '../images/logo1.png';
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import '../components/mediaScreen.css';
import AuthContext from '../context/AuthContext';

export const Navbar = () => {
  const { isLoggedIn, setIsLoggedIn, handleLogout,setRefreshPage,refreshPage } = useContext(AuthContext);
  // console.log("status", isLoggedIn)
  const role=localStorage.getItem("role");
  useEffect(() => {
    // console.log("Auth Role", role);
  }, [isLoggedIn])
  // const handleLogin = () => {
  //   // Upon successful login, store the authentication token in localStorage and update isLoggedIn
  //   setIsLoggedIn(true)
  // };
  const location = useLocation();
  useEffect(()=>{
    setRefreshPage(refreshPage+1);
  },[])
  useEffect(() => {
    const checkToken = () => {
      if (!localStorage.getItem('token')) {
        // The token is not present, user is not authenticated
        setIsLoggedIn(false);
      }else{
        setIsLoggedIn(true)
      }
    };

    checkToken();
  }, [refreshPage]);

  // Check if the current location is the home page
  const isHomePage = location.pathname === '/';
  const isLoginPage = location.pathname === '/login';
  // const handleLogout = () => {
  //   // Send a request to the backend to logout
  //   // Upon successful logout, remove the token from localStorage and update isLoggedIn
  //   localStorage.removeItem('token');
  //   setIsLoggedIn(false);
  // };
  // const [mobile, setMobile] = useState(false)

  // const [corRotated, setcorRotated] = useState(true);
  // const [corSubMenu, setcorSubMenu] = useState(false);

  // const oncorClick = () => {
  //   setcorRotated(!corRotated);
  //   setcorSubMenu(!corSubMenu);
  // }

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        {/* <a class="navbar-brand" href="#">Navbar</a> */}
        <Link to='/' class="navbar-brand">
          <img src={logo} alt="dy" className='logo' />
        </Link>
        <Link to='/'>
          <h2 className='text_nav'> STEPS OF LEARNING PROCESS</h2>
        </Link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            {!isHomePage && (
              <li className="nav-item">
                <NavLink to="/" className="nav-link">
                  Home
                </NavLink>
              </li>
            )}
            <li class="nav-item">
             
                <NavLink to='/about' className="nav-link">
                  About
                </NavLink>
             
            </li>
            <li class="nav-item dropdown">
              <Link class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Certification
              </Link>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <Link to="/courses/html_css" className="dropdown-item" >
                  HTML
                </Link>
               
                <Link to="/courses/js" className="dropdown-item" >
                  JAVASCRIPT
                </Link>


                <Link to="/courses/react" className="dropdown-item" >
                  React
                </Link>
                <Link to="/courses/node" className="dropdown-item" >
                Node                </Link>
                <Link to="/courses/testing" className="dropdown-item" >
                  Testing
                </Link>
                <Link to="/courses/python" className="dropdown-item" >
                  Python
                </Link>
              </div>
            </li>
            <li class="nav-item dropdown">
              <Link class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Learning & Development
              </Link>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <Link to="/courses/hr" className="dropdown-item" >
                  HR Training
                </Link>
                <Link to="/trainerProfile" className="dropdown-item" >
                  Trainer Profile
                </Link>

                <Link to="/under_development" className="dropdown-item" >
                  Strategic Core Member
                </Link>
                <Link to="/under_development" className="dropdown-item" >
                  It Training
                </Link>
                <Link to="/under_development" className="dropdown-item" >
                  Blogs
                </Link>
              </div>
            </li>

            <li class="nav-item">
             
                <NavLink to='/services' className="nav-link">
                  Services
                </NavLink>
             
            </li>
            <li class="nav-item">

              <NavLink to='/contact' className="nav-link">
                Contact
              </NavLink>

            </li>
            {isLoggedIn ? (
              role === 'admin' ? (
                <li className="nav-item">
                  <NavLink to="/admin" className="nav-link">
                    Admin-Home
                  </NavLink>
                </li>
              ) : (
                <li className="nav-item">
                  <NavLink to="/userPage" className="nav-link">
                    Classroom
                  </NavLink>
                </li>
              )
            ) : null}

            <li class="nav-item">
             
                {(isLoggedIn) ? (
                  <NavLink to='/' onClick={handleLogout} className="nav-link">
                    Logout
                  </NavLink>
                ) : (
                  <NavLink to='/login' className="nav-link" >
                    Login
                  </NavLink>
                )}
            

            </li>

          </ul>
        </div>
      </nav>


    </>
  );
};

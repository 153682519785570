import React from 'react'
import { usePageMetadata } from '../hooks/usePageMetadata ';
import { CourcesAbout, CourcesAbout_Instructor, CourcesForm, CourcesJavascript, DayaAbout } from './component';
import { ReactAbout, ReactAboutInstructor, ReactCourse, ReactForm } from './data/ReactContent';

export const React_Course = () => {
    const title = "React Certification";
    const description = "Your page description goes here.";
    const keywords = "keyword1, keyword2, keyword3";
    usePageMetadata(title, description, keywords);

    return (
        <>
        <CourcesForm data={ReactForm} />
        <CourcesJavascript data={ReactCourse} />
        <CourcesAbout data={ReactAbout} />
        <CourcesAbout_Instructor data={ReactAboutInstructor} />
        <DayaAbout data={ReactForm} />
      </>
    )
}

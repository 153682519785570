import React from 'react'

export const QApage = () => {
  return (
   <>
   <h1>Interview Q & A</h1>
     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae temporibus cum expedita consectetur aut iure nesciunt cumque accusantium laborum consequuntur. A excepturi corporis, facere consectetur aut repellat iusto deleniti voluptatem sit obcaecati est. Enim aspernatur quis repudiandae quod eum quidem illo animi ratione molestias modi sequi laborum ad, aperiam iste. Aut, doloremque nisi! Deleniti sed fugit consequuntur sunt quidem inventore quis nam, ducimus dolor ab ad eligendi quas reiciendis, harum, debitis laudantium quam omnis consequatur deserunt. Explicabo sunt excepturi placeat molestias. Maxime quos velit alias corporis, ullam ducimus necessitatibus. Iure illo voluptatibus quas cum magni quidem, laudantium possimus adipisci vero!</p>
   </>
  )
}

import React from 'react'
import { CourcesAbout, CourcesAbout_Instructor, CourcesForm, CourcesJavascript, DayaAbout } from './component'
import { HRAbout, HRAboutInstructor, HRCourse, HRForm } from './data/HRContent'
import { usePageMetadata } from '../hooks/usePageMetadata ';

export const HR_Course = () => {
    const title = "HR";
    const description = "Your page description goes here.";
    const keywords = "keyword1, keyword2, keyword3";

    usePageMetadata(title, description, keywords);
    return (
        <>
            <CourcesForm data={HRForm} />
            <CourcesJavascript data={HRCourse}/>
            <CourcesAbout data={HRAbout} />
            <CourcesAbout_Instructor data={HRAboutInstructor} />
            <DayaAbout data={HRForm} />
        </>
    )

}

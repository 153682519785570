import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "../services/css/counselling.css";
import "../services/css/mediaScreen.css";
import MultiSelectDropdown from "../layouts/MultiSelectDropdown";
import AuthContext from "../context/AuthContext";
// import { BACKEND_URL } from "../config/constraints";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { usePageMetadata } from "../hooks/usePageMetadata ";

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("First Name is required"),

  Email: Yup.string()
    .email("Invalid Email format")
    .required("Email is required"),
  ConfirmEmail: Yup.string()
    .email("Invalid Email format")
    .required("Email confirmation is required")
    .oneOf([Yup.ref('Email')], "Emails do not match"),

});

export const AddUser = () => {
  const title = "Registeration";
  const description = "Your page description goes here.";
  const keywords = "keyword1, keyword2, keyword3";

  usePageMetadata(title, description, keywords);
  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  /**
   * Initializes state variables for different programming languages.
   * @returns None
   */
  // const [selectedOption, setSelectedOption] = React.useState('');
  const [isHTML, setIsHTML] = useState(false);
  const [isCSS, setIsCSS] = useState(false);
  const [isJS, setIsJS] = useState(false);
  const [isReact, setIsReact] = useState(false);
  const [isNode, setIsNode] = useState(false);
  const [isPython, setIsPython] = useState(false);
  const [isJava, setIsJava] = useState(false);
  const [isTesting, setISTesting] = useState(false);
  const [isSoftSkill, setISSOftSkill] = useState(false);



  // const handleOptionChange = (option) => {
  //   setSelectedOption(option);
  // };

  /* This code is checking the authentication context and redirecting the user to the login page if
  certain conditions are met. */
  const { role, success, errorHandleLogout, setIsLoggedIn } = React.useContext(AuthContext);
  const token = localStorage.getItem('token');
  if (token && role !== "admin" && success === true) {
    <Navigate to="/login" />
  }
  const options = [
    '1) HTML', '2) CSS', '3) JAVASCRIPT', '4) REACT JS',
    // '1) HTML', '2) CSS', '3) JAVASCRIPT', '4) REACT JS',
    // '1) HTML', '2) CSS', '3) JAVASCRIPT', '4) REACT JS',
    // '1) HTML', '2) CSS', '3) JAVASCRIPT', '4) REACT JS'
  ];
  const [selectedValues, setSelectedValues] = useState([]);
  const [gpassword, setgPassword] = useState("");
  const handleSelectionChange = (newSelection) => {
    setSelectedValues(newSelection);
  };

  /**
   * Manages the visibility of the password and confirm password fields.
   * @returns None
   */
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleConPasswordVisibility = () => {
    setShowConPassword((prevShowPassword) => !prevShowPassword);
  };


  /**
   * An object representing the initial values for a form.
   * @type {Object}
   * @property {string} Name - The initial value for the Name field.
   * @property {string} Email - The initial value for the Email field.
   * @property {string} ConfirmEmail - The initial value for the ConfirmEmail field.
   * @property {string} password - The initial value for the password field.
   * @property {string} confirmPassword - The initial value for the confirmPassword field.
   */
  const initialValues = {
    Name: "",
    Email: "",
    ConfirmEmail: gpassword,
    password: gpassword,
    confirmPassword: "",

  };

  /**
   * Creates a headers object with an Authorization header containing a bearer token.
   * @param {string} token - The bearer token to include in the Authorization header.
   * @returns {Object} - An object with the Authorization header.
   */
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  /**
   * Handles the form submission by sending a POST request to the backend API with the form values.
   * @param {object} values - The form values object.
   * @param {function} resetForm - A function to reset the form after submission.
   * @returns None
   */

  const handleSubmit = async (values, { resetForm }) => {
    // console.log(values);
    // console.log('Selected Values:', selectedValues);
    /**
     * Creates a data object to be sent as a POST request.
     * @param {Object} values - An object containing the values for name, email, password, and confirmPassword.
     * @param {string} gpassword - The password to be included in the data object.
     * @param {boolean} isHTML - A boolean indicating whether HTML is included in the data object.
     * @param {boolean} isCSS - A boolean indicating whether CSS is included in the data object.
     * @param {boolean} isJS - A boolean indicating whether JavaScript is included in the data object.
     * @param {boolean} isReact - A boolean indicating whether React is included in the data object.
     * @param {boolean} isNode - A
     */
    const postData = {
      name: values.Name,
      email: values.Email,
      password: gpassword,
      confirmPassword: gpassword,
      isHTML: isHTML,
      isCSS: isCSS,
      isJS: isJS,
      isREACT: isReact,
      isNODE: isNode,
      isPYTHON: isPython,
      isJAVA: isJava,
      isTESTING: isTesting,
      isSOFTSKILL: isSoftSkill

    };
    // console.log(postData)
    let loadingToast; // Declare the variable outside the try block

    try {
      // Display loading state
      loadingToast = toast.loading("Registration in progress...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      // Make the API request
      const response = await axios.post(`${BACKEND_URL}/users/register`, postData, { headers: headers });

      // Handle the response
      if (response) {
        // Request was successful
        const data = response.data;
        toast.success("Registration successful!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        resetForm();
      } else {
        // Request failed
        toast.error("Registration failed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (err) {
      // Handle errors
      alert("Session Timeout", err);
      errorHandleLogout();
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  /**
   * Generates a random password consisting of alphanumeric characters.
   * @returns {string} - The randomly generated password.
   */
  const generatePassword = () =>
    setgPassword(
      Array.from(
        { length: 8 },
        () =>
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"[
          Math.floor(Math.random() * 62)
          ]
      ).join("")
    );


  return (
    <div className="cover">
      <div className="c-small">
        <div className="containerCoun">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form className="form">
                <h2 style={{ color: "white" }}>Add User Form</h2>
                <div className="project">
                  <label htmlFor="Name">
                    Full Name
                    <Field
                      type="text"
                      id="Name"
                      placeholder="Enter your full name"
                      name="Name"
                    />
                  </label>
                  <ErrorMessage name="Name" className="error" component="div" />
                </div>
                <div className="project">
                  <label htmlFor="Email">
                    Email Address
                    <Field
                      type="text"
                      id="Email"
                      placeholder="Enter your Email"
                      name="Email"
                    />
                  </label>
                  <ErrorMessage
                    name="Email"
                    className="error"
                    component="div"
                  />
                </div>
                <div className="project">
                  <label htmlFor="ConfirmEmail">
                    Confirm Email Address
                    <Field
                      type="text"
                      id="ConfirmEmail"
                      placeholder="Enter your Email"
                      name="ConfirmEmail"
                    />
                  </label>
                  <ErrorMessage
                    name="ConfirmEmail"
                    className="error"
                    component="div"
                  />
                </div>
                {/* <div className="project">
                  <MultiSelectDropdown
                    options={options}
                    selectedOptions={selectedValues}
                    onSelectionChange={handleSelectionChange}
                  />
                 

                 

                </div> */}
                <h3>Assign Course</h3>
                <div className="choose_course" style={{ marginBottom: 0 }}>

                  <input
                    type="checkbox"
                    name="isHTML"
                    checked={isHTML}
                    onChange={() => setIsHTML(!isHTML)}
                  />
                  HTML
                  <input
                    type="checkbox"
                    name="isCSS"

                    checked={isCSS}
                    onChange={() => setIsCSS(!isCSS)}
                  />
                  css
                  <input
                    type="checkbox"
                    name="isJS"
                    checked={isJS}
                    onChange={() => setIsJS(!isJS)}
                  />
                  JavaScript
                  <input
                    type="checkbox"
                    name="isReact"
                    checked={isReact}
                    onChange={() => setIsReact(!isReact)}
                  />
                  React
                  <input
                    type="checkbox"
                    name="isNode"
                    checked={isNode}
                    onChange={() => setIsNode(!isNode)}
                  />
                  Node

                </div>

                <div className="choose_course" >
                  <label htmlFor="isPython">
                    <input
                      type="checkbox"
                      name="isPython"
                      checked={isPython}
                      onChange={() => setIsPython(!isPython)}
                    />
                    Python</label>
                  <label htmlFor="isJava">
                    <input
                      type="checkbox"
                      name="isJava"
                      checked={isJava}
                      onChange={() => setIsJava(!isJava)}
                    />
                    JAVA</label>
                  <label htmlFor="isTesting">
                    <input
                      type="checkbox"
                      name="isTesting"
                      checked={isTesting}
                      onChange={() => setISTesting(!isTesting)}
                    />
                    Testing</label>
                  <label htmlFor="isSoftSkill">
                    <input
                      type="checkbox"
                      name="isSoftSkill"
                      checked={isSoftSkill}
                      onChange={() => setISSOftSkill(!isSoftSkill)}
                    />
                    Soft Skill</label>

                </div>


                <div className="passForm">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="password">
                      Password:
                      <Field
                        id="password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        value={gpassword}
                      />
                      <i
                        className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                        aria-hidden="true"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </label>
                    <button
                      type="button"
                      style={{
                        color: "white",
                        border: "1px solid white",
                        borderRadius: "10px",
                        padding: "10px",
                        marginTop: "30px",
                        backgroundColor: "#00715d",
                      }}
                      onClick={generatePassword}
                    >
                      Generate Password
                    </button>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="confirmPassword">
                      Confirm Password:
                      <Field
                        type={showConPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={gpassword}
                      />
                      <i
                        className={`fa ${showConPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                        aria-hidden="true"
                        onClick={toggleConPasswordVisibility}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </label>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <button type="submit" className="submit">
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer autoClose={2500}/>
    </div>
  );
};
